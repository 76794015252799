<template>
	<div class="page">
		<Head />
		<div class="page-banner" :style="{'background-image': 'url('+banner+')'}"></div>
		<div class="page-wrap">
			<div class="container">
				<div class="material-cells">
					<div class="material-cell" v-for="(item,index) in material" :key="index">
						<div class="material-cell-hd">{{item.title}}</div>
						<div class="material-cell-bd">{{item.desc}}</div>
					</div>
				</div>
			</div>
		</div>
		<Foot :nvlang="lang"/>
	</div>
</template>

<script>
	// @ is an alias to /src
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	import {mapState} from 'vuex';
	export default {
		name: 'Home',
		components: {
			Head,
			Foot
		},
		data() {
			return {
				// lang:'',
				banner:require('../assets/images/cailiao_banner.jpg'),
				material:[{
					title:'碳化物属性',
					desc:'水泥硬质合金产品具有优良的机械和物理特性。'
				},{
					title:'磨蚀阻力',
					desc:'碳化物对磨蚀的非凡抵抗力是其最重要的属性。在磨料应用中，硬质合金可以比某些耐磨钢合金长100到1倍。'
				},{
					title:'偏转阻力',
					desc:'水泥硬质合金具有很高的弹性模块，在暴露于弯曲力时提供最小的偏转。事实上，弹性的模块是钢的三倍。'
				},{
					title:'耐腐蚀性',
					desc:'由于碳化物通常是化学惰性的（考虑结合材料作为一个因素），它们可以在许多化学和腐蚀性环境中成功使用。'
				},{
					title:'扭转力',
					desc:'碳化物的转子模组是高速钢的两倍，是旋转应用的首选材料。'
				},{
					title:'抗压强度',
					desc:'一些等级的碳化物与钴粘合剂在超高压缩下表现完美无瑕，并已非常成功地用于压力应用高达100万psi。'
				},{
					title:'韧性',
					desc:'硬质合金等级具有较高的活页夹含量，具有极好的抗冲击能力。'
				},{
					title:'低温耐磨性',
					desc:'即使在低至 -453°F 的低温下，硬质合金仍能保持良好的耐磨性，并提供相对较低的摩擦系数，无法使用润滑油。'
				},{
					title:'高温耐磨性',
					desc:'在1,000°F时，硬质合金仍然保持超过90%的硬度。某些等级甚至可以在 2，000°F（间歇性温度下更高）保持显著强度。'
				}]
			};
		},
		computed:{
			...mapState({
				'lang':state=>state.app.currentLang
			}),
		},
		created() {
			if(!this.current){
				this.$router.push({ path:'/'})
			}
		},
		methods: {
			// langchange(data){
			// 	this.lang = data;
			// },
			// goTo(url) {
			// 	this.$router.push(url);
			// }
		}
	}
</script>
